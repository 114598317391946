aside {
  padding: 0 2rem 0;
  background: $aside-background-color;
  width: 100%;

  p,
  ul,
  li,
  a {
    color: white;
    font-size: 1.2rem;
    line-height: 1.75rem;
  }

  h1,
  h2,
  h3,
  h4 {
    color: white;
    font-size: 1.6rem;
  }

  .widget {
    /* border-bottom: solid 0.1rem #ffffff33; */
    padding: 0.75rem 0 0.85rem 0;
    /* border-bottom: solid 0.1rem $blockquote-left-color; */
    margin-bottom: 0;
  }

  .widget p {
    margin-bottom: 0.85rem;
  }

  #toc {
    display: none;
  }

  #toc-index-button {
    color: white;
    background-color: #ffffff33;
    padding: 1rem;
    font-size: 1.25;
    border: 0;
  }
  #toc-index-button:hover {
    background-color: #ffffff66;
  }
}
