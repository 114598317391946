@media only screen and (min-width: 1400px) {
    body {
      background-color: $body-color;
    }
  
    header {
      position: sticky;
      z-index: 9;
      top: 0;
      width: $header-width;
      margin: 0 auto;
  
      banner {
        width: $banner-width;
        margin: 0 auto;
      }
    }
  
    aside {
      background: $aside-background-color-full-width;
      margin: 0 auto;
      padding: 0 1rem 0;
      width: $top-aside-width;
    }
    main {
      max-width: $body-width;
      margin: auto;
  
      article h1,
      h2,
      h3,
      h4 {
        scroll-margin-top: $scroll-margin-top;
      }
    }
    footer {
      width: $footer-width;
      margin: 0 auto;
    }
    #myBtn {
      padding: 15px; /* Some padding */
      border-radius: 10px; /* Rounded corners */
      font-size: 18px; /* Increase font size */
    }
  }