footer {
    text-align: center;
    background: $footer-background-color;
    padding: 1.5rem;
  
    p, a {
      font-size: 0.85rem;
      line-height: 1.1rem;
      color: $footer-paragraph-color;
    }
  
    h4 {
      padding: 0 0 1rem 0;
      font-size: 1.5rem;
      color: whitesmoke;
      text-transform: capitalize;
    }
  }