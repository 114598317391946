/* Widths Alternate - 100% set for Mobile First Design  */
$body-width: 80%;
$header-width: 100%;
$banner-width: 80%;
$top-aside-width: 80%;
$footer-aside-width: 100%;
$footer-width: 100%;
$grid-width: 100%;

$post-card-max-width: 350px;
$post-card-min-width: 275px;

/* Colours - START */
@import "./default-colours";

$header-background-color:  #000;
$mobile-nav-background-color: #111;
$mobile-nav-font-color: #eee;

$site-title-heading-color: white;
$site-title-description-color: #fcfcfc;

$burger-menu-colour: white;

$main-article-background-color: black;
$paragraph-font-color: #f0f0f0;
$heading-color: #fff;

$article-main-heading-background-color: $secondary-colour;
$article-main-heading-color: #fefefe;
$article-headings-color: #ff0000e3;
$article-headings-background-color: #111;
$article-links: $secondary-colour-links;
$article-links-hover: $secondary-colour-links-hover;

$post-card-background-colour: #000;
$post-card-button-colour: #ff00009d;
$post-card-button-text-colour: white;
$post-card-button-hover: #ff0000e3;
$post-card-button-text-hover-colour: white;

$pagination-active-colour: white;
$pagination-active-background: $primary-colour;
$pagination-active-border: $primary-colour;

$pagination-page-link-colour: $primary-colour;
$pagination-background-colour: white;
$pagination-border-colour: 1px solid #dee2e6;

$pagination-item-disabled-colour: #6c757d;
$pagination-item-disabled-background: white;
$pagination-item-border-colour: #dee2e6;

/* Sidebar - Aside */
$aside-background-color-full-width: $secondary-colour;
$aside-background-color: $secondary-colour;
$blockquote-left-color: #c0faaf;
/* Colours - END */

/* Footer */
$footer-aside: $primary-colour;
$footer-background-color: #111;
$footer-paragraph-color: #eee;
/* Footer - END */

/* Font-sizes */
$site-title-heading-font-size: 1.5rem;
$site-title-heading-line-height: 1.5rem;
$site-title-p-font-size: 1rem;
$site-title-p-line-height: 1.2rem;

$paragraph-font-size: 1.2rem;
$paragraph-line-height: 2.2rem;
$h1-font-size: 3.2rem;
$h2-font-size: 3rem;
$h3-font-size: 2.8rem;
$h4-font-size: 2.6rem;
$h5-font-size: 2.4rem;
$h6-font-size: 2.2rem;

$mobile-menu-font-size: 1.5rem;
$mobile-menu-line-height: 1.5rem;

/* Scroll Margin To Compensate For Fixed Header */
$scroll-margin-top: 115px;

/* Back To Top Button */
$hover-button-background-color: #ff00008f;
$hover-button-background-color-hovered: #ff0000e5;
