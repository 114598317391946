main {
  padding: 0 0 2rem 0;
  background: $main-article-background-color;

  iframe {
    padding: 1rem;
  }
  
  p {
    padding: 0 1rem 0 1rem;
  }

  article a {
    padding: 0 3px;
    background: $article-links;
  }

  article a:hover {
    background: $article-links-hover;
    color: whitesmoke;
  }

  article h1 {
    background: $article-main-heading-background-color;
    color: $article-main-heading-color;
    padding: 1.5rem 0.5rem 1.5rem 0.5rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
    line-height: 2.2rem;
    font-size: 1.8rem;
    font-weight: bold;
  }

  article h2,
  h3,
  h4 {
    /* background: $article-headings-background-color; */
    color: $article-headings-color;
    padding: 1.5rem 0.5rem 1.5rem 0.5rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
    line-height: 1.9rem;
    margin-top: 3rem;
    font-size: 1.8rem;

    background-image: linear-gradient(
      to right,
      $article-headings-background-color,
      $main-article-background-color
    );
  }

  article h2 {
    text-transform: uppercase;
  }

  article h3 {
    font-size: 1.6rem;
  }

  .list-items {
    padding: 0;
    display: flex;
    justify-content: space-around;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 1rem 0;
  }

  .card {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-content: center;
    flex-basis: auto;
    max-width: $post-card-max-width;
    min-width: $post-card-min-width;
    padding: 0.25rem 0.25rem 0.7rem 0.25rem;
    margin: 0.5rem;
    background-color: $post-card-background-colour;
    justify-content: space-around;

    a,
    a:hover {
      background: #0000;
    }

    h2 {
      line-height: 1rem;
      font-size: 1.25rem;
      background: rgba(255, 255, 255, 255);
      padding: 0;
      margin: 0;
      background: #0000;
    }

    .card-summary {
      padding: 0.5rem;
      font-size: 1rem;
      line-height: 1.5rem;
      color: #777;
    }

    .card-button {
      color: $post-card-button-text-colour;
      background-color: $post-card-button-colour;
      width: 100%;
      padding: 1rem;
      border: 0;
      margin: auto;
    }

    .card-button:hover {
      cursor: pointer;
      color: $post-card-button-text-hover-colour;
      background-color: $post-card-button-hover;
      font-size: 1.75;
    }

    .featured-image-list {
      width: 100%;
    }
  }

  .list-item ul {
    padding: 0 0 0 2rem;
  }

  .list-item li {
    list-style: none;
    padding: 0.25rem;
  }

  article ul li {
    list-style: square;
    list-style-position: inside;
  }
}
