body {
    background: $body-color;
    font-family: 'Poppins', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $heading-color;
    line-height: 2.0rem;
    text-transform: capitalize;
}

main article h1 {
    color: $article-headings-color;
    background: $article-headings-background-color;
}

h1 {
    font-size: $h1-font-size;
}

h2 {
    font-size: $h2-font-size;
}

h3 {
    font-size: $h3-font-size;
}

h4 {
    font-size: $h4-font-size;
}

h5 {
    font-size: $h5-font-size;
}

h6 {
    font-size: $h6-font-size;
}

p {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    line-height: $paragraph-line-height;
    font-size: $paragraph-font-size;
    color: $paragraph-font-color;
}


a {
    font-size: $paragraph-font-size;
    color: $paragraph-font-color;
    text-decoration: none;
}

li {
    font-size: $paragraph-font-size;
    text-transform: capitalize;
    color: $paragraph-font-color;
    list-style: none;
    line-height: 2rem;
}

img {
    max-width: 100%;
}

main article ul li {
    padding: 0.1rem 0.5rem 0.85rem 2.0rem;
}

#TableOfContents {
    padding: 0.75rem 0 0.75rem 0;

    ul {
        padding-left: 0.35rem;
        margin: 0;
    }
}

blockquote {
    border-left: solid $blockquote-left-color 1rem;
    margin: 1.75rem 0 0.5rem 1.25rem;
    padding: 0;

    p {
        margin: 0 0 0 0.75rem;
        padding: 0.5rem 0 0.5rem 0;
    }
}