@import "./variables";
@import "./reset";
@import "./setup";
@import "./header";
@import "./main_article_body";
@import "./aside";
@import "./footer";

strong {font-weight: 600;}

#footer-aside {
  background-color: $footer-aside;
  h4 {
    padding: 0.75rem 0 1rem;
    text-align: center;
  }
}
.share-icons {
  text-align: center;
  a {
    display: inline-block;
    padding: 0 0.35rem 1rem 0.35rem;
    margin: 0 0.35rem;
  }
}

.featured-image-page {
  padding: 0;
  width: auto;
  height: 45vh;
  object-fit: cover;
  display: inline;
}

.featured-image-list {
  padding: 0.5rem;
  width: auto;
  height: 300px;
  object-fit: contain;
}

img {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

/* Pagination */
.pagination li {
  list-style: none;
  border-radius: 0.25rem;
  margin: 0.5rem 0;
  padding: 1.5rem 0;
}

.pagination {
  display: flex;
  justify-content: center;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.5rem;
  line-height: 1rem;
  color: $pagination-page-link-colour;
  background-color: $pagination-background-colour;
  border: $pagination-border-colour;
}

.homepage .page-link,
.listing .page-link {
  background-color: #fff;
}

.page-item.disabled .page-link {
  color: $pagination-item-disabled-colour;
  pointer-events: none;
  cursor: auto;
  background-color:$pagination-item-disabled-background;
  border-color:$pagination-item-border-colour;
}

.page-item.active .page-link {
  z-index: 1;
  color: $pagination-active-colour;
  background-color: $pagination-active-background;
  border-color: $pagination-active-border;
}

#breadcrumbs {
  margin: 0 0 1.5rem;
  padding: 2rem 1rem 2rem 1rem;
  background: $article-main-heading-background-color;
  line-height: 2.9rem;
}

#breadcrumbs a {
  background: #ff00009d;
  color: #fafafa;
  padding: 0.5rem;
  text-transform: capitalize;
}

#breadcrumbs a:hover {
  background: #ff0000e3;
  padding: 0.5rem;
}

#myBtn {
  display: none; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 10px; /* Place the button at the bottom of the page */
  right: 10px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  background-color: $hover-button-background-color; /* Set a background color */
  color: white; /* Text color */
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 10px; /* Some padding */
  border-radius: 10px; /* Rounded corners */
  font-size: 15px; /* Increase font size */
}

#myBtn:hover {
  background-color: $hover-button-background-color-hovered; /* Add a dark-grey background on hover */
}

.grid-layout {
  margin: 0;
  padding: 0;
}
/* Media Queries */
@import "./media_query_1400";

/* @import "./media_query_1600"; */
