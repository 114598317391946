header {
  /* position: fixed; */
  padding: 1.25rem 0.5rem 0.5rem;
  width: 100%;
  text-align: left;
  background-color: $header-background-color;

  banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;

    #branding {
      display: flex;
      width: 100%;
      align-items: center;
    }
  }

  #logo {
    min-width: 8.5rem;
    min-height: 4.5rem;
    max-width: 10.25rem;
    max-height: 5.25rem;
    padding: 0;
    margin: 0;
  }

  #close-menu {
    color: $burger-menu-colour;
    font-size: 2rem;
    font-weight: 600;
    display: none;
  }

  #burger {
    float: right;
    width: 40px;
    height: 30px;
    padding: 0 0.5rem 0 0.5rem;
    #burger-top,
    #burger-middle,
    #burger-bottom {
      background: $burger-menu-colour;
      height: 3px;
      margin: 5px 0 5px 0;
    }
  }

  #burger:hover {
      cursor: pointer;
    }
  

  #main-navigation {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-items: space-evenly;
    justify-content: center;
    flex-wrap: wrap;
    height: 0vh;
    visibility: hidden;
    transition: height 2s;
    background-color: $mobile-nav-background-color;
    margin-top: 0.85rem;
  }

  #main-navigation li,
  a {
    color: $mobile-nav-font-color;
    font-size: $mobile-menu-font-size;
    text-align: center;
    text-transform: uppercase;
    line-height: $mobile-menu-line-height;
    padding: 1rem;
  }

  #main-navigation .active {
    background-color: #ff00009d;
  }

  li {
    padding: 1rem 0 1rem 0;
  }

  site-title {
    margin: 0;
    padding: 0;
    text-align: left;
    padding-left: 0.5rem;
    text-shadow: 2px 2px 2px #33333344;
  }

  h1 {
    margin: 0;
    padding: 0;
    text-align: left;
    color: $site-title-heading-color;
    font-size: $site-title-heading-font-size;
    line-height: $site-title-heading-line-height;
    text-transform: uppercase;
  }

  p {
    color: $site-title-description-color;
    font-size: $site-title-p-font-size;
    line-height: $site-title-p-line-height;
    text-transform: capitalize;
    padding: 0;
    margin-bottom: 0;
  }

  a {
    line-height: 1rem;
    padding: 0;
  }
}
